import React from "react"
import { IArtwork } from "../../interfaces/models"
import _ from "lodash"
import { PhotographIcon } from "@heroicons/react/outline"
import { getArtworkPreviewUrl } from "../../utils"
import Image from "next/image"

export interface ArtworkMosaicProps {
  data: (IArtwork | undefined)[]
}

const ArtworkMosaic: React.FC<ArtworkMosaicProps> = ({ data }) => {
  const artworkChunk = _.chunk(data, 3)[0]

  if (!artworkChunk) {
    return (
      <div className="flex justify-center items-center border rounded h-full">
        <PhotographIcon className="w-8 h-8" />
      </div>
    )
  }

  return (
    // TODO mansory grid
    <div className="w-full h-full flex flex-wrap">
      {artworkChunk.map(
        (artwork, index) =>
          artwork && (
            <div
              key={index}
              className="relative"
              style={{ flex: "1 0 auto", minWidth: "50%" }}
            >
              <Image
                src={getArtworkPreviewUrl(artwork)}
                layout="fill"
                objectFit="cover"
              />
            </div>
          )
      )}
    </div>
  )
}

export default ArtworkMosaic
