import React from "react"

export interface TitleProps {
  level?: 1 | 2 | 3 | 4 | 5
  children: string
  ellipsis?: boolean
  wrap?: boolean
}

const fontSizes = ["", "text-2xl", "text-xl", "text-lg", "text-base", "text-sm"]

const Title: React.FC<TitleProps> = ({
  children,
  level = 1,
  wrap,
  ellipsis,
}) => {
  return (
    <h1
      title={children}
      className={`font-bold mb-2
      ${fontSizes[level]} 
      ${wrap ? "whitespace-normal" : "whitespace-nowrap"} 
      ${ellipsis ? "text-ellipsis overflow-hidden" : ""}`}
    >
      {children}
    </h1>
  )
}

export default Title
