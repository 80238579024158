import React, { FC, useEffect, useRef } from "react"
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js"
import "video.js/dist/video-js.css"
import { devLog } from "../../utils"

export interface VideoPlayerProps {
  options: VideoJsPlayerOptions
  onReady?: (player: VideoJsPlayer) => void
}

export const VideoJS: FC<VideoPlayerProps> = ({ options, onReady }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const playerRef = useRef<VideoJsPlayer | null>(null)

  useEffect(() => {
    let player = playerRef.current

    if (!player) {
      const videoElement = videoRef.current

      if (!videoElement) return

      player = playerRef.current = videojs(videoElement, options, () => {
        devLog("player is ready")
        onReady && onReady(player as VideoJsPlayer)
      })
    } else {
      player.autoplay(options.autoplay ?? false)
      player.src(options.sources ?? [])
    }
  }, [options, videoRef])

  useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  return (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js" />
    </div>
  )
}

export default VideoJS
