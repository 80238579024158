import React, { useState } from "react"
import useUserCollections from "../../hooks/use-user-collections"
import { uiHandleError } from "../../utils"
import CollectionListModal from "./collection-list-modal"
import { Button } from "../button"
import { useTranslation } from "react-i18next"

export interface SaveToCollectionButtonProps {
  artworkId: string
}

const SaveToCollectionButton: React.FC<SaveToCollectionButtonProps> = ({
  artworkId,
}) => {
  const { t } = useTranslation()
  const { collections, loading, saveItemToCollection } =
    useUserCollections(artworkId)

  const [showModal, setShowModal] = useState(false)

  const handleSaveItem = async (collectionIds: string[]) => {
    try {
      const savingPromises = collectionIds.map(collectionId => {
        return saveItemToCollection(collectionId, artworkId)
      })

      await Promise.all(savingPromises)
      setShowModal(false)
    } catch (e: any) {
      uiHandleError(e)
    }
  }

  return (
    <div>
      <div
        className="flex flex-col"
        onClick={() => {
          setShowModal(true)
        }}
      >
        <Button type="primary">
          <div className="p-2 text-center w-full">
            {t("button:saveToCollection")}
          </div>
        </Button>
      </div>
      <CollectionListModal
        visible={showModal}
        data={collections || []}
        loading={loading}
        highlightedItemId={artworkId}
        onConfirm={handleSaveItem}
        onCancel={() => {
          setShowModal(false)
        }}
      />
    </div>
  )
}

export default SaveToCollectionButton
