import React, { FC, useRef, useState } from "react"
import { IArtwork } from "../../interfaces/models"
import Image from "next/image"
import { ErrorView } from "../feedback"
import { useTranslation } from "react-i18next"
import { VideoJsPlayer, VideoJsPlayerOptions } from "video.js"
import { VideoPlayer } from "../video-player"

export interface ArtworkViewerProps {
  artwork: IArtwork
}

const ArtworkImage: FC<ArtworkViewerProps> = ({ artwork }) => {
  const { id, name } = artwork

  return (
    <Image
      src={`/api/v1/artworks/${id}/preview`}
      layout="fill"
      objectFit="contain"
      alt={name}
    />
  )
}

const ArtworkVideo: FC<ArtworkViewerProps> = ({ artwork }) => {
  const playerRef = useRef<VideoJsPlayer | null>(null)
  const [videoPlayerOptions, setVideoPlayerOptions] =
    useState<VideoJsPlayerOptions>({
      autoplay: true,
      controls: true,
      responsive: true,
      fill: true,
      techOrder: ["html5"],
      sources: [
        {
          src: `/api/v1/artworks/${artwork.id}/preview`,
          type: "application/x-mpegURL",
        },
      ],
    })

  return (
    <VideoPlayer
      options={videoPlayerOptions}
      onReady={player => {
        playerRef.current = player
      }}
    />
  )
}

const TaxonomyUnsupported: FC<ArtworkViewerProps> = ({ artwork }) => {
  const { t } = useTranslation()

  return (
    <div className="text-white flex justify-center items-center h-full">
      <div className="text-center space-y-4 max-w-xl">
        <p>{t("error:taxonomyNotSupportedForPreview")}</p>
      </div>
    </div>
  )
}

const TaxonomyViewers: { [key: string]: FC<ArtworkViewerProps> } = {
  video: ArtworkVideo,
  reel: ArtworkVideo,
  photo: ArtworkImage,
  poster: ArtworkImage,
  "press-kit": ArtworkImage,
  wallpaper: ArtworkImage,
}

const ArtworkViewer: React.FC<ArtworkViewerProps> = ({ artwork }) => {
  const { taxonomy } = artwork
  const { t } = useTranslation()

  if (!taxonomy) {
    return <ErrorView error={new Error(t("error:taxonomyNotProvided"))} />
  }

  const Viewer = TaxonomyViewers[taxonomy?.name] || TaxonomyUnsupported

  return <Viewer artwork={artwork} />
}

export default ArtworkViewer
