import axios from "axios"
import useSWR from "swr"

const useUserCollections = (artworkId?: string) => {
  const { data, error, isValidating, mutate } = useSWR(
    [`/api/v1/collections`, { artworkId }],
    (url, params) => axios.get(url, { params }).then(res => res.data)
  )

  if (error) {
    console.error(error)
  }

  const saveItemToCollection = async (
    collectionId: string,
    artworkId: string
  ) => {
    const response = await axios.post(
      `/api/v1/collections/${collectionId}/add`,
      {
        artworkId,
      }
    )

    mutate()
  }

  return {
    loading: isValidating,
    collections: data?.collections,
    saveItemToCollection,
  }
}

export default useUserCollections
