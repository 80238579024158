import React from "react"
import useSWR from "swr"
import axios from "axios"
import { getAuthorAvatar, getAuthorDisplayName } from "../../utils"
import { ErrorView, Skeleton } from "../feedback"
import Avatar from "../avatar/avatar"
import { useRouter } from "next/router"

export interface AuthorPreviewProps {
  userId: string
}

const AuthorPreview: React.FC<AuthorPreviewProps> = ({ userId }) => {
  const { data, error, isValidating } = useSWR(
    `/api/v1/authors/${encodeURIComponent(userId)}`,
    url => axios.get(url).then(res => res.data)
  )

  const router = useRouter()

  if (isValidating) {
    return <Skeleton />
  }

  if (error) {
    return <ErrorView error={error} />
  }

  if (!data) {
    return null
  }

  return (
    <div
      className="flex space-x-2 cursor-pointer hover:text-arezzo"
      onClick={() => {
        router.push(`/author/${btoa(data?.author.userId)}`)
      }}
    >
      <Avatar src={getAuthorAvatar(data?.author)} />
      <span className="my-auto">{getAuthorDisplayName(data?.author)}</span>
    </div>
  )
}

export default AuthorPreview
