import React from "react"
import Head from "next/head"
import { ellipsisText } from "../../utils"
import { useRouter } from "next/router"

const BRAND_NAME = "Arezzo Media"
// TODO: Add description
const BRAND_DESCRIPTION = "Foto e video per la promozione di Arezzo."

export interface SeoProps {
  title: string
  description?: string | null
  image?: string
}

const Seo: React.FC<SeoProps> = ({ title, description, image }) => {
  const router = useRouter()
  const optimalTitle = ellipsisText(title, 50)

  const finalImage = image || `${process.env.NEXT_PUBLIC_SITE_URL}/og-image.png`
  const finalDescription = description || BRAND_DESCRIPTION

  const finalTitle = `${BRAND_NAME} | ${optimalTitle}`

  return (
    <Head>
      <title>{finalTitle}</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="description" content={finalDescription} />
      <meta name="image" content={finalImage} />

      <meta property="og:url" content={router.asPath} />
      <meta property="og:type" content={"website"} />
      <meta property="og:title" content={finalTitle} />
      <meta property="og:description" content={finalDescription} />
      <meta property="og:image" content={finalImage} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={finalTitle} />
      <meta name="twitter:description" content={finalDescription} />
      <meta name="twitter:image" content={finalImage} />
    </Head>
  )
}

export default Seo
