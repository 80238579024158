import React, { useEffect, useState } from "react"
import * as ReactDOM from "react-dom"
import ConfirmModal, { ConfirmModalProps } from "../components/modal/confirm"

const useConfirm = () => {
  const [visible, setVisible] = useState(false)
  const [config, setConfig] = useState<ConfirmModalProps>({
    message: "",
  })
  const [container, setContainer] = useState<DocumentFragment | null>(null)

  const render = (config: ConfirmModalProps, container: DocumentFragment) => {
    const { onConfirm, onCancel, ...rest } = config

    ReactDOM.render(
      <ConfirmModal
        rounded
        {...rest}
        visible={visible}
        onConfirm={() => {
          onConfirm && onConfirm()
          setVisible(false)
        }}
        onCancel={() => {
          onCancel && onCancel()
          setVisible(false)
        }}
      />,
      container
    )
  }

  useEffect(() => {
    const _container = document.createDocumentFragment()
    setContainer(_container)
    return () => {
      ReactDOM.unmountComponentAtNode(_container)
    }
  }, [])

  useEffect(() => {
    if (container) {
      render(config, container)
    }
  }, [config, container, visible])

  const confirm = (config: ConfirmModalProps) => {
    setVisible(true)
    setConfig({ ...config })
  }

  return {
    confirm,
  }
}

export default useConfirm
