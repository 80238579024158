import { IFormItemProps } from "./interfaces"
import React from "react"
import FormLabel from "./form-label"
import FormError from "./form-error"

export interface InputProps extends IFormItemProps {
  inputType?: "input" | "textarea" | "checkbox"
  type?: string
  placeholder?: string
}

const Input: React.FC<InputProps> = ({
  inputType = "input",
  type,
  placeholder,
  disabled,
  label,
  name,
  rules,
  form,
}) => {
  const {
    register,
    formState: { errors },
  } = form

  const inputClasses = `rounded-md border px-2 py-1 focus:outline-none ${
    errors[name] ? "border-error" : "border-gray-400"
  }`

  if (type === "checkbox") {
    return (
      <div className="space-x-1">
        <input
          id={name}
          type={type}
          disabled={disabled}
          className={inputClasses}
          {...register(name, rules)}
        />
        <label htmlFor={name}>{label || name}</label>
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <FormLabel>{label || name}</FormLabel>
      {inputType === "input" && (
        <input
          id={name}
          type={type}
          disabled={disabled}
          placeholder={placeholder || label || name}
          className={inputClasses}
          {...register(name, rules)}
        />
      )}
      {inputType === "textarea" && (
        <textarea
          id={name}
          disabled={disabled}
          placeholder={placeholder || label || name}
          rows={5}
          className={inputClasses}
          {...register(name, rules)}
        />
      )}
      {errors[name] && <FormError>{errors[name].message}</FormError>}
    </div>
  )
}

export default Input
