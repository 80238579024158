export enum ActivityTypes {
  Search = "SEARCH",
  Download = "DOWNLOAD",
  Like = "LIKE",
  Unlike = "UNLIKE",

  // category
  CreateCategory = "CREATE_CATEGORY",
  UpdateCategory = "UPDATE_CATEGORY",
  DeleteCategory = "DELETE_CATEGORY",

  // taxonomy
  CreateTaxonomy = "CREATE_TAXONOMY",
  UpdateTaxonomy = "UPDATE_TAXONOMY",
  DeleteTaxonomy = "DELETE_TAXONOMY",

  // tag
  CreateTag = "CREATE_TAG",
  UpdateTag = "UPDATE_TAG",
  DeleteTag = "DELETE_TAG",

  // operators
  InviteMember = "INVITE_MEMBER",
  UpdateRole = "UPDATE_ROLE",
  DeleteOperator = "DELETE_OPERATOR",

  // artwork
  CreateArtwork = "CREATE_ARTWORK",
  UpdateArtwork = "UPDATE_ARTWORK",
  DeleteArtwork = "DELETE_ARTWORK",

  // author
  CreateAuthor = "CREATE_AUTHOR",
  UpdateAuthor = "UPDATE_AUTHOR",
  DeleteAuthor = "DELETE_AUTHOR",

  // organization
  CreateOrganization = "CREATE_ORGANIZATION",
  UpdateOrganization = "UPDATE_ORGANIZATION",
  DeleteOrganization = "DELETE_ORGANIZATION",
}

export enum Licenses {
  RoyaltyFree = "ROYALTY_FREE",
  CC_BY_NC_4 = "CC_BY_NC_4",
  CC_BY_NC_ND_4 = "CC_BY_NC_ND_4",
}

export enum UserRoles {
  Admin = "ADMIN",
  Editor = "EDITOR",
}

export enum PublishStates {
  Draft = "DRAFT",
  Published = "PUBLISHED",
  Archived = "ARCHIVED",
}
