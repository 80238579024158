import React from "react"
import useSWR from "swr"
import { Skeleton } from "../feedback"
import axios from "axios"
import Alert from "../feedback/alert"
import { useTranslation } from "react-i18next"
import { formatFileSize, mergeClasses } from "../../utils"
import { Text } from "../typography"

export interface FileMetaPreviewProps {
  artworkId: string
}

const FileMetaPreview: React.FC<FileMetaPreviewProps> = ({ artworkId }) => {
  const { t } = useTranslation()
  const { data, error, isValidating } = useSWR(
    `/api/v1/artworks/${artworkId}/meta`,
    url => axios.get(url).then(res => res.data)
  )

  if (isValidating) {
    return <Skeleton />
  }

  if (error) {
    return <Alert message={error.message} />
  }

  if (!data) {
    return null
  }

  const { name, mimeType, extension, size, width, height } = data.fileData

  const mappedData: any[] = [
    // {
    //   name: t("label:filename"),
    //   value: name,
    // },
    {
      name: t("label:mimeType"),
      value: mimeType,
    },
    {
      name: t("label:extension"),
      value: extension,
    },
    {
      name: t("label:size"),
      value: formatFileSize(size),
    },
    {
      name: t("label:width"),
      value: width,
    },
    {
      name: t("label:height"),
      value: height,
    },
  ]

  return (
    <dl>
      {mappedData.map((data: any, index: number) => (
        <div
          key={index}
          className={mergeClasses([
            "px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
            index % 2 === 0 ? "bg-gray-50" : "bg-white",
          ])}
        >
          <dt className="text-sm font-medium text-gray-500">{data.name}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <Text ellipsis={true}>{data.value}</Text>
          </dd>
        </div>
      ))}
    </dl>
  )
}

export default FileMetaPreview
