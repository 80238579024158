import React from "react"
import Image from "next/image"

export interface AvatarProps {
  src: string
  size?: number
}

const Avatar: React.FC<AvatarProps> = ({ src, size = 32 }) => {
  return (
    <div
      className="relative my-auto rounded-full overflow-hidden"
      style={{
        width: size,
        height: size,
      }}
    >
      <Image src={src} layout="fill" objectFit="contain" />
    </div>
  )
}

export default Avatar
