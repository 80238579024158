import { ICollection } from "../../interfaces/models"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import axios from "axios"
import { uiHandleError } from "../../utils"
import Modal from "../modal/modal"
import NameForm from "../forms/name-form"
import { PlusIcon } from "@heroicons/react/solid"

interface CreateCollectionButtonProps {
  onCreate?: (collection: ICollection) => void
}

const CreateCollectionButton: React.FC<CreateCollectionButtonProps> = ({
  onCreate,
}) => {
  const { t } = useTranslation()

  const [showInputModal, setShowInputModal] = useState(false)

  const handleFormSubmit = async ({ name }: any) => {
    try {
      const response = await axios.post(`/api/v1/collections`, {
        name,
      })

      setShowInputModal(false)

      onCreate &&
        onCreate({
          id: response.data.collectionId,
          name,
          items: [],
        })
    } catch (error: any) {
      uiHandleError(error)
    }
  }

  return (
    <>
      <Modal
        rounded
        size="small"
        visible={showInputModal}
        onClose={() => {
          setShowInputModal(false)
        }}
        title={t("label:createCollection")}
      >
        <NameForm onFinish={handleFormSubmit} />
      </Modal>
      <div
        className="w-full h-full cursor-pointer"
        onClick={() => {
          setShowInputModal(true)
        }}
      >
        <div className="rounded-lg shadow-lg overflow-hidden text-center bg-arezzo-light w-full h-full p-2">
          <div className="space-y-2 m-auto text-white border border-[#8C807C] w-full h-full rounded-lg p-8">
            <div className="flex flex-col items-center justify-center">
              <PlusIcon
                className="text-arezzo w-10 h-10"
                width="1em"
                height="1em"
              />
              <div>
                <h1 className="text-[18px] font-bold text-[#8C807C]">
                  {t("button:createCollection")}
                </h1>
                <div className="flex space-x-2 justify-center">
                  <span className="text-[#8C807C] text-[12px]">
                    {t("label:createCollectionDescription")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateCollectionButton
