import React from "react"
import { useTranslation } from "react-i18next"
import { DocumentSearchIcon } from "@heroicons/react/outline"

export interface EmptyProps {
  message?: string
}

const Empty: React.FC<EmptyProps> = ({ message }) => {
  const { t } = useTranslation()
  return (
    <div className="min-h-[150px] flex flex-col justify-center items-center bg-[#F3ECEA] p-20 space-y-4">
      <div>
        <DocumentSearchIcon className="w-32 h-32 text-slate-500" />
      </div>
      <p className="text-slate-500">
        {message || t("message:noDataAvailable")}
      </p>
    </div>
  )
}

export default Empty
