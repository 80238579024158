import React, { useMemo } from "react"
import { IArtwork } from "../../interfaces/models"
import { useTranslation } from "react-i18next"
import TagsPreview from "./tags-preview"
import AuthorPreview from "./author-preview"
import FileMetaPreview from "./file-meta-preview"
import { useUser } from "@auth0/nextjs-auth0"
import ArtworkActions from "./artwork-actions"
import { ErrorView, Skeleton } from "../feedback"
import { Button } from "../button"
import Link from "next/link"
import { useRouter } from "next/router"
import { Title } from "../typography"
import ArtworkLicensePreview from "./artwork-license-preview"

interface DetailGroupProps {
  title: string
}

const DetailGroup: React.FC<DetailGroupProps> = ({ title, children }) => {
  return (
    <div className="pb-4">
      <Title level={3}>{title}</Title>
      <hr className="pb-4" />
      {children}
    </div>
  )
}

export interface ArtworkDetailProps {
  data: IArtwork
}

const ArtworkDetail: React.FC<ArtworkDetailProps> = ({ data }) => {
  const { t } = useTranslation()
  const { user, isLoading, error } = useUser()
  const router = useRouter()

  const actions = useMemo(() => {
    if (isLoading) {
      return <Skeleton />
    }

    if (error) {
      return <ErrorView error={error} />
    }

    if (user) {
      return <ArtworkActions data={data} />
    }

    return (
      <Link href={`/api/auth/login?returnTo=${router.asPath}`}>
        <Button>{t("button:login")}</Button>
      </Link>
    )
  }, [user, isLoading, error, data])

  return (
    <div>
      <Title ellipsis>{data.name}</Title>
      <p>{data.description}</p>
      <br />

      <DetailGroup title={t("label:author")}>
        <AuthorPreview userId={data.authorId} />
      </DetailGroup>

      <DetailGroup title={t("label:tags")}>
        <TagsPreview artworkId={data.id} />
      </DetailGroup>

      <DetailGroup title={t("label:techSheet")}>
        <FileMetaPreview artworkId={data.id} />
      </DetailGroup>

      <DetailGroup title={t("label:license")}>
        <ArtworkLicensePreview artwork={data} />
      </DetailGroup>

      <DetailGroup title={t("label:actions")}>{actions}</DetailGroup>
    </div>
  )
}

export default ArtworkDetail
