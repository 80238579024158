import React, { useState } from "react"
import { IFormProps } from "./interfaces"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import Input from "./input"
import { Button } from "../button"

export interface NameFormProps extends IFormProps {}

interface Inputs {
  name: string
}

const NameForm: React.FC<NameFormProps> = ({
  initialValues,
  onFinish,
  showSubmitButton = true,
  id,
}) => {
  const { t } = useTranslation()

  const [schema] = useState(
    yup
      .object({
        name: yup
          .string()
          .required(t("error:firstnameRequired"))
          .max(256, "error:maxLengthExceeded"),
      })
      .required()
  )

  const form = useForm<Inputs>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  })

  return (
    <form
      id={id}
      className={"p-4 space-y-4"}
      onSubmit={form.handleSubmit(onFinish)}
    >
      <Input label={t("label:name")} name="name" form={form} />
      {showSubmitButton && (
        <Button block htmlType="submit" type="primary">
          {t("button:submit")}
        </Button>
      )}
    </form>
  )
}

export default NameForm
