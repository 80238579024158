import { RefObject, useEffect, useRef, useState } from "react"

export interface UseIntersectionOptions {
  rootMargin?: string
}

const defaultOptions = {
  rootMargin: "0px",
}

const useIntersection = (
  options: UseIntersectionOptions = {}
): [RefObject<HTMLDivElement>, boolean] => {
  const finalOptions = { ...defaultOptions, ...options }
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting)
      },
      {
        ...finalOptions,
      }
    )

    ref.current && observer.observe(ref.current)

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [])

  return [ref, isVisible]
}

export default useIntersection
