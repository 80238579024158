import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { IArtwork } from "../../interfaces/models"
import { Licenses } from "../../interfaces/enums"

export interface ArtworkLicensePreviewProps {
  artwork: IArtwork
}

const ArtworkLicensePreview: React.FC<ArtworkLicensePreviewProps> = ({
  artwork,
}) => {
  const { t } = useTranslation()

  const license = useMemo(() => {
    if (artwork.license === Licenses.CC_BY_NC_ND_4) {
      return (
        <div>
          <a
            rel="license noreferrer"
            target="_blank"
            href="https://creativecommons.org/licenses/by-nc-nd/4.0/deed.it"
          >
            <img
              alt="Licenza Creative Commons"
              src="https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png"
            />
          </a>
          <br />
          {t("label:ccLicense")}
          <br />
          <br />
          <p>{t("message:needCommercialUse")}</p>
          <a className="text-arezzo" href="mailto:arezzomedia@arezzointour.it">
            arezzomedia@arezzointour.it
          </a>
        </div>
      )
    }

    if (artwork.license === Licenses.CC_BY_NC_4) {
      return (
        <div>
          <a
            rel="license noreferrer"
            target="_blank"
            href="https://creativecommons.org/licenses/by-nc/4.0/deed.it"
          >
            <img
              alt="Licenza Creative Commons"
              style={{ borderWidth: 0 }}
              src="https://i.creativecommons.org/l/by/4.0/88x31.png"
            />
          </a>
          <br />
          Quest&apos;opera è distribuita con Licenza
          <a
            rel="license noreferrer"
            target="_blank"
            href="https://creativecommons.org/licenses/by-nc/4.0/deed.it"
          >
            Creative Commons Attribuzione - Non commerciale 4.0 Internazionale
            (CC BY-NC 4.0)
          </a>
          <p>{t("message:needCommercialUse")}</p>
          <a className="text-arezzo" href="mailto:arezzomedia@arezzointour.it">
            arezzomedia@arezzointour.it
          </a>
        </div>
      )
    }

    return (
      <div>
        <a
          rel="license noreferrer"
          target="_blank"
          href="https://creativecommons.org/licenses/by-nc-nd/4.0/"
        >
          <img
            alt="Licenza Creative Commons"
            src="https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png"
          />
        </a>
        <br />
        {t("label:ccLicense")}
        <br />
        <br />
        <p>{t("message:needCommercialUse")}</p>
        <a className="text-arezzo" href="mailto:arezzomedia@arezzointour.it">
          arezzomedia@arezzointour.it
        </a>
      </div>
    )
  }, [artwork])

  return <div>{license}</div>
}

export default ArtworkLicensePreview
