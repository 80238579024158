import React from "react"
import useSWR from "swr"
import axios from "axios"
import { ErrorView, Skeleton } from "../feedback"
import { ITag } from "../../interfaces/models"
import { Tag } from "../tag"

export interface TagsPreviewProps {
  artworkId: string
}

const TagsPreview: React.FC<TagsPreviewProps> = ({ artworkId }) => {
  const { data, error, isValidating } = useSWR(
    `/api/v1/artworks/${artworkId}/tags`,
    url => axios.get(url).then(res => res.data)
  )

  if (isValidating) {
    return <Skeleton />
  }

  if (error) {
    return <ErrorView error={error} />
  }

  if (!data) {
    return null
  }

  return (
    <div className="flex flex-wrap">
      {data.tags.map((tag: ITag, index: number) => (
        <Tag
          key={index}
          data={tag}
          onClick={() => {
            // TODO: handle Tag click
          }}
        />
      ))}
    </div>
  )
}

export default TagsPreview
