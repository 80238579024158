import React, { useEffect, useState } from "react"
import { IArtwork, ICollection } from "../../interfaces/models"
import Modal from "../modal/modal"
import { LoadingView } from "../feedback"
import { Button } from "../button"
import { useTranslation } from "react-i18next"
import CreateCollectionButton from "./create-collection-button"
import ArtworkMosaic from "./artwork-mosaic"
import { mergeClasses } from "../../utils"
import { CheckIcon } from "@heroicons/react/outline"

export interface CollectionListModalProps {
  loading: boolean
  data: ICollection[]
  highlightedItemId?: string
  onConfirm?: (collectionIds: string[]) => void
  onCancel?: () => void
  visible?: boolean
}

const CollectionListModal: React.FC<CollectionListModalProps> = ({
  loading = false,
  data,
  highlightedItemId,
  onConfirm,
  visible = false,
  onCancel,
}) => {
  const { t } = useTranslation()

  const [selectedCollectionIds, setSelectedCollectionIds] = useState<string[]>(
    []
  )

  const [internalCollections, setInternalCollections] = useState([...data])

  useEffect(() => {
    setInternalCollections([...data])
  }, [data])

  const addCollectionId = (collectionId: string) => {
    setSelectedCollectionIds([...selectedCollectionIds, collectionId])
  }

  const removeCollectionId = (collectionId: string) => {
    setSelectedCollectionIds(
      selectedCollectionIds.filter(id => id !== collectionId)
    )
  }

  return (
    <Modal
      rounded
      title={t("label:saveToCollection")}
      visible={visible}
      onClose={() => {
        setSelectedCollectionIds([])
        onCancel && onCancel()
      }}
    >
      {loading ? (
        <LoadingView />
      ) : (
        <div className="p-4 space-y-6">
          <ul className="space-y-2">
            {internalCollections.map((collection, index) => {
              const alreadyHasItem = Boolean(collection.focusedItem?.length)
              const isActive =
                alreadyHasItem || selectedCollectionIds.includes(collection.id)

              const artworks = collection.items
                .map(({ artwork }) => artwork)
                .filter(Boolean) as IArtwork[]

              return (
                <li
                  className={mergeClasses([
                    "rounded border border-2 p-2 cursor-pointer",
                    isActive ? "border-arezzo" : "",
                  ])}
                  key={index}
                  onClick={() => {
                    if (!alreadyHasItem) {
                      isActive
                        ? removeCollectionId(collection.id)
                        : addCollectionId(collection.id)
                    }
                  }}
                >
                  <div className="flex align-center items-center space-x-4">
                    <div className="flex-none w-[200px] h-[100px] relative">
                      <ArtworkMosaic data={artworks} />
                      {isActive && (
                        <div className="absolute top-0 right-0 bottom-0 left-0 bg-arezzo bg-opacity-50 justify-center items-center flex">
                          <CheckIcon className="w-8 h-8 text-white m-auto" />
                        </div>
                      )}
                    </div>
                    <p>{collection.name}</p>
                  </div>
                </li>
              )
            })}
          </ul>
          <CreateCollectionButton
            onCreate={collection =>
              setInternalCollections([...internalCollections, collection])
            }
          />
          <div className="text-center">
            <Button
              type="primary"
              disabled={selectedCollectionIds.length === 0}
              onClick={() => {
                onConfirm && onConfirm(selectedCollectionIds)
              }}
            >
              <div className="p-2">{t("button:saveToCollection")}</div>
            </Button>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default CollectionListModal
