import React, { useMemo } from "react"
import { IArtwork } from "../../interfaces/models"
import { useTranslation } from "react-i18next"
import { Button } from "../button"
import { formatPrice, uiHandleError } from "../../utils"
import axios from "axios"
import { useRouter } from "next/router"
import useSWR from "swr"
import { Skeleton } from "../feedback"
import SaveToCollectionButton from "../collections/save-to-collection-button"

const priceList = [
  { label: "Original", price: "100,00€" },
  { label: "8K", price: "15,00€" },
  { label: "4K", price: "9,00€" },
  { label: "Full HD", price: "FREE" },
  { label: "HD", price: "FREE" },
]

export interface ArtworkActionsProps {
  data: IArtwork
}

const ArtworkActions: React.FC<ArtworkActionsProps> = ({ data }) => {
  const { t } = useTranslation()
  const router = useRouter()

  const {
    data: fetchedData,
    error,
    isValidating,
  } = useSWR(`/api/v1/artworks/${data.id}/user-status`, url =>
    axios.get(url).then(res => res.data)
  )

  const handleDownload = async () => {
    try {
      // const response = await axios.get(`/api/v1/artworks/${data.id}/download`)
      // const downloadUrl = response.data?.downloadUrl
      // if (!downloadUrl) {
      //   throw new Error("Cannot download file")
      // }

      const downloadUrl = `/api/v1/artworks/${data.id}/download`
      const a = document.createElement("a")
      a.href = downloadUrl
      a.target = "_blank"
      a.click()
    } catch (e: any) {
      uiHandleError(e)
    }
  }

  const handlePurchaseButtonClick = async () => {
    try {
      const response = await axios.post(`/api/v1/checkout/${data.id}/intent`)
      const { clientSecret } = response.data
      router.push(`/checkout/${data.id}?clientSecret=${clientSecret}`)
    } catch (e: any) {
      uiHandleError(e)
    }
  }

  const purchaseOrDownload = useMemo(() => {
    if (data.price === 0) {
      return (
        <Button type="primary" onClick={handleDownload}>
          <div className="flex justify-center p-2 w-full">
            {t("button:download")}
          </div>
        </Button>
      )
    }

    const { hasItem, likesItem } = fetchedData || {}

    return hasItem ? (
      <Button type="primary" onClick={handleDownload}>
        <div className="p-2 w-full text-center">{t("button:download")}</div>
      </Button>
    ) : (
      <Button type="primary" onClick={handlePurchaseButtonClick}>
        <div className="flex justify-between p-2 w-full">
          <div> {t("button:purchase")}</div>
          <div>{formatPrice(data.price)}</div>
        </div>
      </Button>
    )
  }, [data, fetchedData])

  if (isValidating) {
    return <Skeleton />
  }

  return (
    <div className="flex flex-col space-y-2">
      {/*{priceList.map((price, index) => (*/}
      {/*  <div*/}
      {/*    key={index}*/}
      {/*    className="flex justify-between p-2 border border-2 border-slate-400 hover:border-arezzo rounded cursor-pointer"*/}
      {/*  >*/}
      {/*    <div>{price.label}</div>*/}
      {/*    <div>{price.price}</div>*/}
      {/*  </div>*/}
      {/*))}*/}

      <SaveToCollectionButton artworkId={data.id} />
      {purchaseOrDownload}
    </div>
  )
}

export default ArtworkActions
