import React, { useEffect, useState } from "react"
import { IArtwork } from "../../interfaces/models"
import { nanoid } from "nanoid"

import { DEFAULT_PREVIEW_IMAGE_HEIGHT } from "../../constants/application-settings"
import { ArtworkContainer } from "./index"
import Modal from "../modal/modal"
import { useRouter } from "next/router"
import Gallery, { RenderImageProps } from "react-photo-gallery"
import _ from "lodash"
import LikeButton from "../button/like-button"
import Image from "next/image"
import { useTranslation } from "react-i18next"
import useIntersection from "../../hooks/use-intersection"
import Empty from "../feedback/empty"
import useConfirm from "../../hooks/use-confirm"
import { TrashIcon } from "@heroicons/react/outline"
import { EndOfList } from "../feedback"

const MIN_GALLERY_ITEM_LENGTH = 3

export interface ArtworkGalleryProps {
  data: IArtwork[]
  onClick?: (artworkId: string) => void
  onLoadMore?: () => void
  canLoadMore?: boolean
  showContextMenu?: boolean
  onDelete?: (artworkId: string) => void
}

interface CustomGalleryImage {}

const tailingGalleryItems = Array.from(
  { length: MIN_GALLERY_ITEM_LENGTH },
  (_, i) => ({
    id: nanoid(),
    width: 1,
    height: 1,
    src: "",
    backgroundColor: "transparent",
  })
)

const mapData = (artwork: IArtwork): any => {
  const { name, picture, id } = artwork
  const scale = picture.height / DEFAULT_PREVIEW_IMAGE_HEIGHT

  return {
    id,
    title: name,
    src: `/api/v1/previews/${picture.id}?type=buffer`,
    height: DEFAULT_PREVIEW_IMAGE_HEIGHT,
    width: picture.width / scale,
    backgroundColor: artwork.dominantColor,
  }
}

const ArtworkGallery: React.FC<ArtworkGalleryProps> = ({
  data,
  onClick,
  canLoadMore = false,
  onLoadMore,
  showContextMenu = false,
  onDelete,
}) => {
  const [mappedData, setMappedData] = useState<any>([])
  const [artworkId, setArtworkId] = useState<string | null>(null)
  const router = useRouter()
  const { t } = useTranslation()

  const { confirm } = useConfirm()

  const [footerRef, inViewport] = useIntersection({})

  useEffect(() => {
    if (inViewport && canLoadMore) {
      onLoadMore && onLoadMore()
    }
  }, [inViewport, canLoadMore])

  useEffect(() => {
    if (data.length > 0 && data.length < MIN_GALLERY_ITEM_LENGTH) {
      const [chunk] = _.chunk(
        tailingGalleryItems,
        MIN_GALLERY_ITEM_LENGTH - data.length
      )

      setMappedData(data.map(mapData).concat(chunk))
    } else {
      setMappedData(data.map(mapData))
    }
  }, [data])

  const handleArtworkOpen = (artworkId: string) => {
    setArtworkId(artworkId)
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
        },
      },
      `/artwork/${artworkId}`,
      { shallow: true }
    )
  }

  const ImageRenderer: React.ComponentType<RenderImageProps<any>> = ({
    index,
    photo,
    margin,
    direction,
    top,
    left,
  }) => {
    const { src, backgroundColor, alt, title, id } = photo
    const imgStyle = {
      transition:
        "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s",
    }

    if (!src) {
      return (
        <div
          key={id}
          style={{
            margin,
            height: 0,
            width: photo.width,
            backgroundColor: backgroundColor,
            overflow: "hidden",
            position: "relative",
            pointerEvents: "none",
          }}
        />
      )
    }

    return (
      <div
        key={id}
        className="relative cursor-pointer overflow-hidden"
        style={{
          margin,
          height: photo.height,
          width: photo.width,
          backgroundColor: backgroundColor,
        }}
      >
        <Image src={photo.src} alt={title} layout="fill" />
        <div
          className="absolute
          top-0 right-0 bottom-0 left-0
          opacity-0 hover:opacity-100
          transition-all duration-300
          hover:bg-black/30 hover:backdrop-blur-sm
          p-2"
          onClick={() => {
            onClick && onClick(id)
            handleArtworkOpen(id)
          }}
        >
          <div className="absolute top-4 left-4">
            <LikeButton artworkId={id} />
          </div>
          {showContextMenu && (
            <div className="absolute top-4 right-4">
              <div
                className="rounded-full w-8 p-2 text-red-500 bg-white cursor-pointer"
                onClick={evt => {
                  evt.stopPropagation()
                  onDelete && onDelete(id)
                }}
              >
                <TrashIcon />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={"py-8"}>
      {mappedData.length === 0 && <Empty />}
      {mappedData.length > 0 && (
        <div style={{ minHeight: 800 }}>
          <Gallery photos={mappedData} renderImage={ImageRenderer} />
        </div>
      )}
      <div ref={footerRef}>
        {onLoadMore &&
          mappedData.length > 0 &&
          (canLoadMore ? null : <EndOfList />)}
      </div>
      <Modal
        size="large"
        visible={Boolean(artworkId)}
        onClose={() => {
          setArtworkId(null)
          router.push(
            {
              pathname: router.pathname,
              query: {
                ...router.query,
              },
            },
            undefined,
            {
              shallow: true,
            }
          )
        }}
      >
        {artworkId && (
          <ArtworkContainer
            artworkId={artworkId}
            onClick={artwork => {
              setArtworkId(artwork.id)
            }}
          />
        )}
      </Modal>
    </div>
  )
}

export default ArtworkGallery
