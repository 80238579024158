import { IArtwork } from "../../interfaces/models"
import React from "react"
import useSWR from "swr"
import axios from "axios"
import { useTranslation } from "react-i18next"
import Spinner from "../feedback/spinner"
import { ErrorView } from "../feedback"
import Image from "next/image"

export interface ArtworkRelatedItemsProps {
  data: IArtwork
  onClick?: (artwork: IArtwork) => void
}

const ArtworkRelatedItems: React.FC<ArtworkRelatedItemsProps> = ({
  data,
  onClick,
}) => {
  const { t } = useTranslation()
  const {
    data: remoteData,
    isValidating,
    error,
  } = useSWR(`/api/v1/artworks/${data.id}/related`, url =>
    axios.get(url).then(res => res.data)
  )

  if (isValidating) {
    return (
      <div style={{ height: 188 }}>
        <Spinner dark />
      </div>
    )
  }

  if (error) {
    return <ErrorView error={error} />
  }

  if (!remoteData) {
    return null
  }

  if (remoteData.artworks.length === 0) {
    return null
  }

  return (
    <div className="">
      <span className="text-white font-bold ml-4">{t("label:related")}</span>
      <div className="overflow-x-auto">
        <ul className="flex flex-row space-x-2">
          {remoteData.artworks.map((artwork: IArtwork, index: number) => (
            <li
              className="cursor-pointer border-transparent border-2 opacity-75 hover:border-arezzo hover:opacity-100 transition-all"
              key={index}
              onClick={() => {
                onClick && onClick(artwork)
              }}
            >
              <div className="relative w-40 h-40">
                <Image
                  width={artwork.picture.width}
                  height={artwork.picture.height}
                  src={`/api/v1/previews/${artwork.picture.id}?type=buffer`}
                  layout="fill"
                  alt={artwork.name}
                  objectFit="contain"
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ArtworkRelatedItems
