import React from "react"
import useSWR from "swr"
import axios from "axios"
import { ErrorView, LoadingView } from "../feedback"
import ArtworkDetail from "./artwork-detail"
import ArtworkRelatedItems from "./artwork-related-items"
import { IArtwork } from "../../interfaces/models"
import ArtworkViewer from "./artwork-viewer"
import { Seo } from "../seo"
import { devLog, getSeoDataFromArtwork } from "../../utils"

export interface ArtworkContainerProps {
  artworkId: string
  artwork?: IArtwork
  onClick?: (artwork: IArtwork) => void
}

const ArtworkContainer: React.FC<ArtworkContainerProps> = ({
                                                             artworkId,
                                                             onClick,
                                                             artwork,
                                                           }) => {
  const { data, error, isValidating } = useSWR(
    `/api/v1/artworks/${artworkId}`,
    url => axios.get(url).then(res => res.data)
  )

  if (isValidating && !artwork) {
    return <LoadingView />
  }

  if (error) {
    return <ErrorView error={error} />
  }

  const artworkToShow = artwork || data?.artwork

  devLog({ artworkToShow })

  if (!artworkToShow) {
    //TODO 404
    return null
  }

  return (
    <>
      <Seo {...getSeoDataFromArtwork(artworkToShow)} />
      <div className="lg:flex lg:flex-row">
        <div className="lg:w-3/4 bg-black">
          <div className="flex flex-col justify-between h-full">
            <div className="relative min-h-[600px] h-full">
              <ArtworkViewer artwork={artworkToShow} />
            </div>
            {artworkToShow && (
              <ArtworkRelatedItems data={artworkToShow} onClick={onClick} />
            )}
          </div>
        </div>
        <div className="lg:w-1/4 px-8 py-12 bg-white lg:max-h-[800px] lg:overflow-y-auto overflow-x-hidden">
          <ArtworkDetail data={artworkToShow} />
        </div>
      </div>
    </>
  )
}

export default ArtworkContainer
