import React from "react"
import { mergeClasses } from "../../utils"

type TextTypes =
  | "default"
  | "secondary"
  | "success"
  | "warning"
  | "danger"
  | "disabled"
  | "underline"
  | "delete"
  | "strong"
  | "italic"
  | "link"

interface TextProps {
  children: string
  type?: TextTypes
  wrap?: boolean
  ellipsis?: boolean
}

const textClasses: { [key: string]: string } = {
  default: "text-black",
  secondary: "text-gray-500",
  success: "text-green-500",
  warning: "text-yellow-500",
  danger: "text-red-500",
  disabled: "text-gray-500",
  underline: "underline",
  delete: "line-through",
  strong: "font-bold",
  italic: "italic",
  link: "text-blue-500 cursor-pointer",
}

const Text: React.FC<TextProps> = ({
  children,
  type = "default",
  ellipsis = false,
  wrap = true,
}) => {
  return (
    <p
      title={children}
      className={mergeClasses([
        textClasses[type] ?? textClasses.default,
        wrap ? "whitespace-normal" : "whitespace-nowrap",
        ellipsis ? "text-ellipsis overflow-hidden" : "",
      ])}
    >
      {children}
    </p>
  )
}

export default Text
